import { CLEAR_INITIATE_TRANSACTION, INITIATE_TRANSACTION_FAIL, INITIATE_TRANSACTION_REQUEST, INITIATE_TRANSACTION_SUCCESS } from "../constants/constants";


const initialState = {
  initiate_loading : false,
  transaction_initiate: {},
  initiate_success: false,
  initiate_error : false
}

    export const InitiateTransactionReducer = (state = initialState, action) => {
    switch (action.type) {
      case INITIATE_TRANSACTION_REQUEST:
        return {
          ...state,
          initiate_loading : true 
       };
      case INITIATE_TRANSACTION_SUCCESS:
        return {
          ...state,
         initiate_loading: false,
          transaction_initiate : action.payload,
          initiate_success : true
        };
      case INITIATE_TRANSACTION_FAIL:
        return {
          ...state,
          initiate_loading: false,
          initiate_error: action.payload,
          initiate_success : false
        };
      case CLEAR_INITIATE_TRANSACTION: 
        return {
          ...state,
            initiate_error: "",
            initiate_success: false,
            initiate_loading: false
        }
    default:
        return state;
    }
  };
  