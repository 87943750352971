import React, {useContext} from 'react';
import { AppContext } from '../../../AppContexts/AppContext';
import "./share.css";

const ShareOptionsModal = ({children}) => {
    const {openShareModal,setOpenShareModal} = useContext(AppContext)
  return (
    <div className={openShareModal ? "share-options share-modal-show" : "share-options"}>
       
        <div className="share-option-modal">
           <h4 className="share-header">Share</h4>
            {children}
        </div>
        <div className="share-option-overlay" onClick={() => setOpenShareModal(prev => !prev)}></div>
    </div>
  )
}

export default ShareOptionsModal