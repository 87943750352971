import { USER_LOGIN_FAIL, USER_LOGIN_REQUEST, USER_LOGIN_SUCCESS } from "../constants/constants";

    export const LoginReducer = (state = {}, action) => {
    switch (action.type) {
      case USER_LOGIN_REQUEST:
        return { loading: true };
      case USER_LOGIN_SUCCESS:
        return {
          ...state,
          login_loading: false,
          logged_in: action.payload
        };
      case USER_LOGIN_FAIL:
        return {
          ...state,
          login_loading: false,
          login_error: action.payload,
        };
    default:
        return state;
    }
  };
  