import { LOCAL_STATIONS_GET_FAIL, LOCAL_STATIONS_GET_REQUEST, LOCAL_STATIONS_GET_SUCCESS } from "../constants/constants";

    export const LocalStationReducer = (state = {}, action) => {
    switch (action.type) {
      case LOCAL_STATIONS_GET_REQUEST:
        return { local_station_loading : true };
      case LOCAL_STATIONS_GET_SUCCESS:
        return {
          ...state,
          local_station_loading: false,
          local_station: action.payload
        };
      case LOCAL_STATIONS_GET_FAIL:
        return {
          ...state,
          local_station_loading: false,
          local_station_error: action.payload,
        };
    default:
        return state;
    }
  };
  