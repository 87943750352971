import { CLEAR_VERIFY_ORDER, VERIFY_ORDER_FAIL, VERIFY_ORDER_REQUEST, VERIFY_ORDER_SUCCESS } from "../constants/constants";
   const initialState = {
      verify_order: {},
      verify_order_loading: false,
      verify_order_error: "",
      verify_order_success: false
   }
    export const VerifyOrderReducer = (state = initialState, action) => {
    switch (action.type) {
      case VERIFY_ORDER_REQUEST:
        return {
           ...state,
           verify_order_loading : true 
        };
      case VERIFY_ORDER_SUCCESS:
        return {
          ...state,
          verify_order_loading: false,
          verify_order_success: true,
          verify_order : action.payload
        };
      case VERIFY_ORDER_FAIL:
        return {
          ...state,
          verify_order_loading: false,
          verify_order_success: false,
          verify_order_error: action.payload,
        };
      case CLEAR_VERIFY_ORDER:
        return {
            ...state,
            verify_order_loading: false,
            verify_order_error: "",
            verify_order_success: false
        }
    default:
        return state;
    }
  };
  