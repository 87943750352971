// import { useContext } from 'react';
export const patterns = {
    telephone: /^\d{11}$/, 
    email: /^([a-z\d\.-_]+)@([a-z\d-]+)\.([a-z]{2,8})(\.[a-z]{2,8})?$/,
}

export const validateForDestopDoorDelivery = (field, regex) => {
  
  if(regex.test(field.value)){
    field.className = "input-text valid"
  }else {
    field.className="input-text invalid"
  }
}

export const validateForDestopPickup = (field, regex) => {
  
  if(regex.test(field.value)){
    field.className = "input-text valid"
  }else {
    field.className="input-text invalid"
  }
}


export const ValidateForMobileDoorDelivery = (field, regex) => {
  if(regex.test(field.value)){
    field.className = "input-text-mobile valid"
  }else {
    field.className = "input-text-mobile invalid"
  }
}

export const ValidateForMobilePickup = (field, regex) => {
  if(regex.test(field.value)){
    field.className = "input-text-mobile valid"
  }else {
    field.className = "input-text-mobile invalid"
  }
}

export const  checkValidation = (regex, value) => {
      return regex.test(value)
}