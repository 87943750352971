import React from 'react';
import "./error.css";
import { Link } from 'react-router-dom';
import { BsArrowLeft } from 'react-icons/bs';

const Error = ({customError, customErrorCode}) => {
  return (
    <div className='error-message'>
          <h1 className='error-header'>{customErrorCode}</h1>
          <p>{customError}</p>
        {
           customErrorCode !== "503" && <Link to="/" className='back-home-btn'><BsArrowLeft /> Back to home</Link> 
        }
       
    </div>
  )
}
export default Error