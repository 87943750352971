import { GET_STORE_FAIL, GET_STORE_REQUEST, GET_STORE_SUCCESS } from "../constants/constants";

export const GetStoreReducer = (state = {}, action) => {
    switch (action.type) {
      case GET_STORE_REQUEST:
        return { loading_store : true };
      case GET_STORE_SUCCESS:
        return {
          ...state,
          loading_store: false,
          store: action.payload
        };
      case GET_STORE_FAIL:
        return {
          ...state,
          loading_store: false,
          store_error: action.payload,
        };
    default:
        return state;
    }
  };