import React from 'react';
import {AiOutlineCaretRight,AiOutlineCaretLeft} from "react-icons/ai"

const ReviewPagination = ({totalPosts, postsPerPage,nextPage,prevPage,currentPage}) => {
    let pages = [];
    for(let i = 1; i <= Math.ceil(totalPosts/postsPerPage); i++){
        pages.push(i)
    }
  return (
    <div className="pagination-btn">
        <button className={pages.length === 1 || currentPage === 1 ? "prev-btn-review disabled-page" : "prev-btn-review"} onClick={() => prevPage(pages)}><AiOutlineCaretLeft className="icon-caret"/></button>
            <button className="active-page-pagination">{currentPage}</button>
        <button className={pages.length === 1 || currentPage === pages.length ? "next-btn-review disabled-page" : "next-btn-review"} onClick={() => nextPage(pages)}><AiOutlineCaretRight className="icon-caret"/></button>
    </div>
  )
}

export default ReviewPagination