import { HOME_DELIVERY_GET_FAIL, HOME_DELIVERY_GET_REQUEST, HOME_DELIVERY_GET_SUCCESS } from "../constants/constants";

    export const GetHomeDeliveryReducer = (state = {}, action) => {
    switch (action.type) {
      case HOME_DELIVERY_GET_REQUEST:
        return { home_delivery_loading : true };
      case HOME_DELIVERY_GET_SUCCESS:
        return {
          ...state,
          home_delivery_loading: false,
          home_delivery: action.payload
        };
      case HOME_DELIVERY_GET_FAIL:
        return {
          ...state,
          home_delivery_loading: false,
          home_delivery_error: action.payload,
        };
    default:
        return state;
    }
  };
  