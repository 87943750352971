import { GET_SUBSCRIPTION_FAIL, GET_SUBSCRIPTION_REQUEST, GET_SUBSCRIPTION_SUCCESS } from "../../constants/constants"

const initialState = {
    get_subscription_loading: false,
    get_subscription_error: "",
    subscriptions: []
}


export const getSubscription = (state=initialState, {type, payload}) => {
     switch(type){
         case GET_SUBSCRIPTION_REQUEST:
            return {
                ...state,
                get_subscription_loading: true
            }
        case GET_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                get_subscription_loading: false,
                subscriptions: payload.results
            }
        case GET_SUBSCRIPTION_FAIL:
            return {
                ...state,
                get_subscription_loading: false,
                get_subscription_error: payload.message
            }
        default:
            return state
     }
}