import { PAYMENT_METHOD_GET_FAIL, PAYMENT_METHOD_GET_REQUEST, PAYMENT_METHOD_GET_SUCCESS } from "../constants/constants";

    export const PaymentMethodReducer = (state = {}, action) => {
    switch (action.type) {
      case PAYMENT_METHOD_GET_REQUEST:
        return {payment_method_loading : true };
      case PAYMENT_METHOD_GET_SUCCESS:
        return {
          ...state,
          payment_method_loading: false,
          payment_method: action.payload
        };
      case PAYMENT_METHOD_GET_FAIL:
        return {
          ...state,
          payment_method_loading: false,
          payment_method_error: action.payload,
        };
    default:
        return state;
    }
  };
  