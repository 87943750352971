import { SEARCH_ALPHA_REQUEST, SEARCH_ALPHA_SUCCESS ,SEARCH_ALPHA_FAIL, CLEAR_SEARCH_ALPHA} from './../constants/constants';
const initialState = {
    searchProductLoading: false,
    searchProductError : "",
    search_results: [],
    search_results_docs: [],
    search_results_code: 0,
    search_success: false
}
export const searchAlphaProducts = (state=initialState, action) => {
     switch(action.type){
         case SEARCH_ALPHA_REQUEST: 
            return {
                ...state,
                searchProductLoading: true,
                searchModal: true
            }
         case SEARCH_ALPHA_SUCCESS: 
             return {
                ...state,
                searchProductLoading: false,
                search_results: action.payload.results,
                search_results_code: action.payload.code,
                search_results_docs: action.payload.results?.docs,
                search_success: true
             }
          case SEARCH_ALPHA_FAIL:
             return {
                ...state,
                searchProductLoading: false,
                searchProductError: action.payload,
             }
           case CLEAR_SEARCH_ALPHA:
              return {
                searchProductLoading: false,
                searchProductError: "",
                search_success: false
              }
            default:
                return state;
     }
}