import { GET_SERVICE_CENTER_DETAILS_FAIL, GET_SERVICE_CENTER_DETAILS_REQUEST, GET_SERVICE_CENTER_DETAILS_SUCCESS } from "../constants/constants";

    export const GetServiceCenterDetailsReducer = (state = {}, action) => {
    switch (action.type) {
      case GET_SERVICE_CENTER_DETAILS_REQUEST:
        return {service_center_loading : true };
      case GET_SERVICE_CENTER_DETAILS_SUCCESS:
        return {
          ...state,
          service_center_loading: false,
          service_center : action.payload
        };
      case GET_SERVICE_CENTER_DETAILS_FAIL:
        return {
          ...state,
          service_center_loading: false,
          service_center_error: action.payload,
        };
    default:
        return state;
    }
  };
  