import { CREATE_ORDER_REQUEST, CREATE_ORDER_SUCCESS,  CREATE_ORDER_FAIL, CLEAR_CREATE_ORDER} from './../constants/constants';
const initialState = {
  create_order_loading : false,
  create_order: {},
  create_order_success: false,
  create_order_error: ""
}
export const CreateOrderReducer = (state = initialState, action) => {
    switch (action.type) {
      case CREATE_ORDER_REQUEST:
        return {
            ...state,
            create_order_loading : true 
        };
      case CREATE_ORDER_SUCCESS:
        return {
          ...state,
          create_order_loading: false,
          create_order : action.payload,
          create_order_success: true
        };
      case CREATE_ORDER_FAIL:
        return {
          ...state,
          create_order_loading: false,
          create_order_error: action.payload,
          create_order_success: false
        };
      case CLEAR_CREATE_ORDER:
        return {
          ...state,
            create_order_error: "",
            create_order_success: false,
            create_order_loading: false
        }
    default:
        return state;
    }
  };