
import { PRODUCT_GET_CLEAR, PRODUCT_GET_FAIL, PRODUCT_GET_REQUEST, PRODUCT_GET_SUCCESS } from "../constants/constants";
const initialState = {
  product_loading: false,
  product: {},
  product_error: "",
  product_success: false
}
    export const GetProductReducer = (state = initialState, action) => {
    switch (action.type) {
      case PRODUCT_GET_REQUEST:
        return { 
           ...state,
           product_loading : true
        };
      case PRODUCT_GET_SUCCESS:
        return {
          ...state,
          product_loading: false,
          product: action.payload,
          product_success: true
        };
      case PRODUCT_GET_FAIL:
        return {
          ...state,
          product_loading: false,
          product_error: action.payload,
          product_success: false
        };
      case PRODUCT_GET_CLEAR:
        return {
          ...state,
            product_error: "",
            product_success: false,
            product_loading: false
        }
    default:
        return state;
    }
  };
  