import { WAITLIST_CREATE_FAIL, WAITLIST_CREATE_REQUEST, WAITLIST_CREATE_SUCCESS } from "../constants/constants"

const initialState = {
    waitlist_loading: false,
    waitlist_success: false,
    waitlist_docs: [],
    waitlists: [],
    error: "",
}

export const WaitListCreate = (state=initialState, action) => {
    switch(action.type){
       case WAITLIST_CREATE_REQUEST: 
           return {
                ...state,
                waitlist_loading : true
           }
       case WAITLIST_CREATE_SUCCESS:
           return {
                 ...state,
                 waitlist_loading : false,
                 waitlist_success : true,
                 waitlists : action.payload      
           }
       case WAITLIST_CREATE_FAIL: 
           return {
                ...state,
                waitlist_loading : false,
                waitlist_success : false,
                error: action.payload
           }
       default:
           return state

    }
}