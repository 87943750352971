import { REFUND_PAYMENT_FAIL, REFUND_PAYMENT_REQUEST, REFUND_PAYMENT_SUCCESS, BANK_LIST_REQUEST, BANK_LIST_SUCCESS  } from "../constants/constants"
import { BANK_LIST_FAIL } from './../constants/constants';

const initialState ={
    refund_loading: false,
    bank_list_loading: false,
    refund_error: "",
    bank_list_error: "",
    bank_list: [],
    refund : {},
    refund_success: false,
    bank_list_success: false
}
export const RefundPayment = (state=initialState, action) => {
      switch(action.type){
            case REFUND_PAYMENT_REQUEST:
                return {
                      ...state,
                      refund_loading: true

                }
            case REFUND_PAYMENT_SUCCESS:
                return {
                    ...state,
                    refund_loading: false,
                    refund_success: true,
                    refund: action.payload
                }
            case REFUND_PAYMENT_FAIL:
                return {
                    ...state,
                    refund_loading: false,
                    refund_success: false,
                    refund_error: action.payload
                }
            default:
                return state
      }
}


export const BankList = (state=initialState, action) => {
    switch(action.type){
        case BANK_LIST_REQUEST:
            return {
                ...state,
                bank_list_loading: true
            }
        case BANK_LIST_SUCCESS:
            return {
                ...state,
                bank_list_loading: false,
                bank_list: action.payload,
                bank_list_success: true
            }
        case BANK_LIST_FAIL: 
            return {
                ...state,
                bank_list_loading: false,
                bank_list_error: action.payload
            }
        default:
            return state
    }
}