import { GIG_PICKUP_LOCATION_GET_FAIL, GIG_PICKUP_LOCATION_GET_REQUEST, GIG_PICKUP_LOCATION_GET_SUCCESS } from "../constants/constants";

    export const GigPickUpReducer = (state = {}, action) => {
    switch (action.type) {
      case GIG_PICKUP_LOCATION_GET_REQUEST:
        return {gig_pickup_loading : true };
      case GIG_PICKUP_LOCATION_GET_SUCCESS:
        return {
          ...state,
          gig_pickup_loading: false,
          gig_pickup: action.payload
        };
      case GIG_PICKUP_LOCATION_GET_FAIL:
        return {
          ...state,
          gig_pickup_loading: false,
          gig_pickup__error: action.payload,
        };
    default:
        return state;
    }
  };
  