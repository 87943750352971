import { GET_CATEGORY_SUCCESS, GET_CATEGORY_REQUEST, GET_CATEGORY_FAIL } from '../../constants/constants';


const initialState = {
    category_loading: false,
    category_error : "",
    list_categories: [],
    list_categories_docs: [],
    list_categories_success: false
}
export const allCategories = (state=initialState, action) => {
     switch(action.type){
         case GET_CATEGORY_REQUEST: 
            return {
                ...state,
                category_loading: true
            }
         case GET_CATEGORY_SUCCESS: 
             return {
                ...state,
                category_loading: false,
                list_categories: action.payload,
                list_categories_docs: action.payload.docs,
                list_categories_success: true
             }
          case GET_CATEGORY_FAIL:
             return {
                ...state,
                category_loading: false,
                category_error: action.payload,
                list_categories_success: false
             }
            default:
                return state;
     }
}
