import { REGISTER_GUEST_USER_FAIL, REGISTER_GUEST_USER_REQUEST, REGISTER_GUEST_USER_SUCCESS } from "../constants/constants";

export const RegisterGuestReducer = (state = {}, action) => {
    switch (action.type) {
      case REGISTER_GUEST_USER_REQUEST:
        return { loading: true };
      case REGISTER_GUEST_USER_SUCCESS:
        return {
          ...state,
          loading: false,
          guest_registered: action.payload
        };
      case REGISTER_GUEST_USER_FAIL:
        return {
          ...state,
          loading: false,
          guest_error: action.payload,
        };
    default:
        return state;
    }
  };