
import VideoPost from "./VideoPost"

const VideoCard = ({setShowVideoModal,showVideoModal, videoUrl}) => {
  return (
    
    <div className={showVideoModal ? "video-card show-modal" : "video-card"}>
        <VideoPost videoUrl={videoUrl} showVideoModal={showVideoModal} setShowVideoModal={setShowVideoModal}/>
    </div>
  )
}

export default VideoCard