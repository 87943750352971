import { GET_ETA_FAIL, GET_ETA_REQUEST, GET_ETA_SUCCESS, CLEAR_ETA_DATA } from "../constants/constants"

const initialState = {
    get_eta_loading: false,
    get_eta_error: "",
    eta: {}
}
export const getEta = (state=initialState, action) => {
    switch(action.type){
       case GET_ETA_REQUEST:
           return {
               ...state,
               get_eta_loading: true
           }
       case GET_ETA_SUCCESS:
           return {
               ...state,
               get_eta_loading: false,
               eta: action.payload
           }
       case GET_ETA_FAIL:
           return {
                ...state,
                get_eta_loading: false,
                get_eta_error: action.payload
           }
       case CLEAR_ETA_DATA: 
          return {
               ...state,
               get_eta_loading: false,
               eta: {},
               get_eta_error :""
          }
       default:
          return state
    }
}